import { styled } from '@mui/system';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import ModuleAccordian from './ModuleAccordion';
import { cyan } from '@mui/material/colors';
import feedback from './imgs/feedback.png';

export function StockDetailBlock() {
  const theme = useTheme();

  return (
    <Article>
      <Grid container spacing={2}>
        {/* Personalised Feedback Section */}
        <Grid item xs={12} md={6} style={{ alignSelf: 'flex-end' }}>
          <Box display="flex" justifyContent="center">
            <Image src={feedback} alt="Feedback" />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} container alignContent="center">
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color={cyan[700]}>
              Get personalised feedback
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Your journey starts with an online assessment. This allows us to give you personalised feedback about your
              parenting. Based on this feedback, we'll recommend specific modules for you.
            </Typography>
          </Grid>
        </Grid>

        {/* Interactive Module Section */}
        <Grid
          item
          xs={12}
          container
          alignContent="center"
          sx={{
            margin: theme.spacing(4, 4, 0, 4),
            [theme.breakpoints.down('sm')]: {
              margin: theme.spacing(4, 0, 0, 0),
            },
          }}>
          <Grid item xs={12} md={10} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color={cyan[700]}>
              Access up to 15 interactive online modules
            </Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <Typography variant="body1" paragraph>
              We will recommend you modules to complete, but you can choose extra modules you want to do. 
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <ModuleAccordian />
        </Grid>
      </Grid>
    </Article>
  );
}

const Article = styled('article')(({ theme }) => ({
  padding: theme.spacing(4, 8, 4, 8),
  backgroundColor: '#f3fcfd',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    padding: theme.spacing(4, 1),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 2),
  },
}));

const Image = styled('img')({
  width: '70%',
  height: '70%',
  objectFit: 'cover',
});
