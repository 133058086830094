import { Button, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { Dialog } from 'components/Dialog';

export const IntroVideoTranscript = () => {
    const [show, setShow] = useState(false);
    return (
      <>
        <Dialog open={show} onClose={() => setShow(false)}>
          <Dialog.Title>Transcript</Dialog.Title>
          <Dialog.Content>
            <Typography>
              Welcome to PiP! This video provides a brief overview of the program and answers some common questions, to help you feel as ready as possible to start the program.
            </Typography>
            <Typography mt={2}>This video will cover:</Typography>
            <ul>
              <li>What PiP aims to do for you and your family</li>
              <li>What is involved in PiP</li>
              <li>How to navigate the different components of PiP</li>
            </ul>
            <Typography >
              Let’s start with what you can gain from PiP.
            </Typography>
            <Typography>
              The PiP program was created to help parents as they support their autistic child. The program aims to help you learn and apply parenting strategies that research shows can help children’s mental health.
            </Typography>
            <br/>
            <Typography>
              As part of the program, you’ll be allocated a ‘PiP coach’.
            </Typography>
            <Typography>
              We call ourselves ‘coaches’ to reflect the way we work with parents. 
            </Typography>
            <br/>
            <Typography>
              We aren’t therapists for you or your child. What we are is a coach, striving to support you as you learn and apply parenting strategies to support your child’s mental health and wellbeing.
            </Typography>
            <br/>
            <Typography>
              You may already know some of the parenting strategies discussed in PiP, but the value of a coach is that they can guide you in setting goals, considering your specific situation, and talking through challenges that arise.
            </Typography>
            <br/>
            <Typography>
              This version of PiP is called Partners in Parenting - Kids for parents of school-aged autistic kids, or ‘PiP Kids-Autism’ for short. It includes specific content, activities, and resources to support parents of autistic children. The content of the program was developed with input from other parents with autistic children, members of the autistic community, and professionals who support these families.
            </Typography>
            <br/>
            <Typography>
              You’ll work with your coach to understand emotions and behaviour, promote your child’s development, connect and build a close relationship with your child, and apply strategies to support your whole family.
            </Typography>
            <br/>
            <Typography>
              You might be wondering if the program is similar to counselling or therapy? The short answer? It isn’t.
            </Typography>
            <br/>
            <Typography>
               We won’t be directly involved with your child, so it is important that you continue to stay in contact with members of your child’s care team throughout the program. 
            </Typography>
            <br/>
            <Typography>
              Similarly, parents supporting an autistic child can face significant challenges and stress. That’s why we might recommend that you seek your own professional support if needed as well.
            </Typography>
            <br/>
            <Typography>
              The parenting strategies in PiP are based on research and are appropriate for most families. However, each family is unique, and sometimes there may be situations or experiences that are not fully addressed by these strategies. Wherever possible, coaches will discuss how these strategies can be adapted to your unique situation.
            </Typography>
            <br/>
            <Typography  mt={2}>
              What’s involved in PiP?
            </Typography>
            <Typography>
              There are three main components of PiP:
            </Typography>
            <ul>
              <li>An initial parenting survey followed by tailored feedback about areas of strength and areas for further development.</li>
              <li>A program of weekly online modules tailored based on your needs and preferences.</li>
              <li>Online coaching sessions with your PiP coach.</li>
            </ul>
            
            <Typography>
              The first step of the PiP program is completing a survey about your parenting. This survey takes about 15 minutes, and many parents find this time useful to reflect on their parenting and goals for the program. 
            </Typography>
            <br/>
            <Typography>
              After this survey, you’ll receive tailored feedback, as well as some recommended modules.
            </Typography>
            <br/>
            <Typography>
              Once you’ve selected your modules, a new module will ‘unlock’ for you each week. You’ll receive an email once each new module is unlocked. Each module focuses on a specific area of parenting.
            </Typography>
            <br/>
            <Typography>
              You complete these modules in your own time, on any device with internet access. Most modules take about 15 to 30 minutes.
            </Typography>
            <br/>
            <Typography>
              At the end of each module, you will be asked to select a goal, to help you practise the strategies covered in that module.
            </Typography>
            <br/>
            <Typography>
              You’ll also attend your 50-minute coaching sessions with your PiP coach. This session builds on the module content, so it’s important that you complete the module before your coaching session. If you have a partner or co-parent involved in your child’s care, they are welcome to join too – just let your coach know. During the coaching session, you will:
            </Typography>
            <ul>
              <li>Review the module content</li>
              <li>Complete some activities that build on the module and help you apply the strategies to your family situation</li>
              <li>Plan how you can implement your chosen goal</li>
            </ul>
          
            <Typography>
              The program is time-limited, so it is important that you set aside your allocated time each week. Your prompt and regular attendance at sessions will allow you to get the most out of the program.
            </Typography>
            <br/>
            <Typography>
              There are two main platforms you will use while completing PiP. The first is the website where you access your modules. The second is the videoconferencing platform, which you’ll use to meet with your coach.
            </Typography>
            <br/>
            <Typography>
              This is the PiP website. When you log into the website, you’ll have access to your personal dashboard, which is where you access your modules, and it shows your progress through the program. If you wish to complete a module that is locked, you can unlock it by clicking this padlock icon.
            </Typography>
            <br/>
            <Typography>
              Move through each module by reviewing the content on screen and clicking the next button. To get back to the dashboard, just open up the navigation bar and click the dashboard icon.
            </Typography>
            <br/>
            <Typography>
              When completing your modules, you may come across additional resources or links. To return to a module from the same tab, simply press the ‘back’ button.
            </Typography>
            <br/>
            <Typography>
              Some links may take you outside of the PiP website. To return to the module from a new tab or website, navigate to the original tab where you accessed the module and press the ‘back’ button. Don’t worry, your module progress will be saved and you can continue where you left off.
            </Typography>
            <br/>
            <Typography>
              You’ll attend your coaching sessions on Zoom, using a private link that only you and your coach can access. You will receive an email with the meeting link. Just click the link and you will be prompted to join the Zoom meeting.
            </Typography>
            <br/>
            <Typography>
              You will need to ensure your microphone, camera, and speakers are working. Then you can join the session with your coach.
            </Typography>
            <br/>
            <Typography>
              We want you to get the most out of your PiP experience, so if you have any questions, don’t hesitate to contact the PiP team.
            </Typography>
            <br/>
            <Typography >
              So, what now?
            </Typography>
            <br/>
            <Typography>
              Well, jump into the program and start by completing your parenting survey. Our team will be in touch shortly to organise your first coaching session, if they haven’t already.
            </Typography>
            <br/>
            <Typography>
              Once again, welcome to PiP, and we look forward to working with you!
            </Typography>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={() => setShow(false)}>Close</Button>
          </Dialog.Actions>
        </Dialog>
        <Link onClick={() => setShow(true)} sx={{ '&:hover': { cursor: 'pointer' } }}>
          Read transcript
        </Link>
      </>
    );
};
