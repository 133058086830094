import { styled } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import { cyan } from '@mui/material/colors';
import delivery from './imgs/delivery.png';

export function StoreBlock() {
  return (
    <Article>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8}>
          <DeliveryImage src={delivery} alt="Successful Delivery" />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="h5" color={cyan[700]}>
            What’s in store for you?
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10}>
          <Typography variant="body1" paragraph>
            PiP Kids-Autism aims to empower you to support your autistic child’s mental health.
            The program includes practical, evidence-based and expert-endorsed strategies designed to build your skills and confidence. 
          </Typography>
        </Grid>
      </Grid>
    </Article>
  );
}

const Article = styled('article')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(8),
  backgroundColor: '#e9f7fa',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(4, 2, 4, 2),
  },
}));

const DeliveryImage = styled('img')(({ theme }) => ({
  width: '80px',
  [theme.breakpoints.down('sm')]: {
    width: '65px',
  },
}));
