import {
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  DialogActions,
  Dialog as MuiDialog,
} from '@mui/material';
import { ReactNode } from 'react';

export const Dialog = (props: DialogProps) => {
  return <MuiDialog disableRestoreFocus {...props} />;
};

Dialog.Actions = function _DialogActions(props: { children: ReactNode }) {
  return (
    <DialogActions sx={{ p: '18px', gap: 1, flexDirection: { xs: 'column', sm: 'row' } }}>
      {props.children}
    </DialogActions>
  );
};

Dialog.Title = DialogTitle;
Dialog.Content = function _DialogContent(props: { children: ReactNode }) {
  return (
    <DialogContent sx={{ pb: 0 }}>
      {typeof props.children === 'string' ? <DialogContentText>{props.children}</DialogContentText> : props.children}
    </DialogContent>
  );
};
