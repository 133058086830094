import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { cyan } from '@mui/material/colors';

export default function ModuleAccordian() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid
      container
      spacing={matches ? 0 : 2}
      sx={{
        textAlign: 'left',
        padding: theme.spacing(0, 12),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(0, 4),
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0, 2),
        },
      }}>
      {modules.map((topic, idx) => {
        return (
          <Grid key={idx} item xs={12} sm={6} container>
            <Grid
              item
              xs={1}
              sx={{
                marginTop: theme.spacing(3.2),
                borderColor: cyan[700],
                borderTopStyle: 'solid',
              }}
            />

            <Grid item xs={11}>
              <Accordion
                elevation={0}
                sx={{
                  backgroundColor: 'transparent',
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    flexDirection: 'row',
                    minHeight: theme.spacing(6.4),
                    height: theme.spacing(6.4),
                    padding: '0 16px',
                    margin: 0,
                    '&.Mui-expanded': {
                      margin: 0,
                      minHeight: theme.spacing(6.4),
                      height: theme.spacing(6.4),
                    },
                  }}>
                  <Typography
                    sx={{
                      fontSize: theme.typography.pxToRem(17),
                      [theme.breakpoints.down('xs')]: {
                        fontSize: theme.typography.pxToRem(15),
                      },
                    }}>
                    {topic.title}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: theme.typography.pxToRem(15),
                      [theme.breakpoints.down('xs')]: {
                        fontSize: theme.typography.pxToRem(13),
                      },
                    }}>
                    {topic.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

const modules = [
  {
    title: 'Autism: The fundamentals',
    description:
      'Having a sound understanding of autism and effectively managing your energy levels will ensure you are best able to support your child. In this module, we will cover key information about autism and neurodiversity, and tips on talking about autism with others and managing your energy as a parent.',
  },
  {
    title: 'Guide your child’s behaviours',
    description:'A variety of factors can interact to influence your child’s behaviour. This module will help you identify the factors that you can influence and manage, to reduce the behaviours that you or your child find challenging.'  },
  {
    title: 'Look after yourself',
    description:'Looking after yourself is not selfish or indulgent. Regular acts of self-care can help to relieve stress, reduce the likelihood of carer fatigue or burnout, and enable you to keep caring for others. This module provides some strategies you can use, which other parents and carers have found helpful.'
  },
  {
    title: 'Support your child’s sensory needs',
    description:'Recognising and understanding your child’s sensory differences and preferences can help you to support your child’s and your own sensory needs. In this module, we will cover understanding the way your child processes information through their senses and their sensory profile, identifying stimming behaviour, and supporting your child by using sensory strategies.'  },
  {
    title: 'Show affection and acceptance',
    description:'Making children feel loved, safe and secure is essential to their overall wellbeing. The first step towards this is showing affection and acceptance. In this module we will take a closer look at different ways you can demonstrate affection and acceptance in a warm and consistent way.'  },
  {
    title: 'Connect',
    description:'Open communication with your child helps them feel more comfortable to talk to you about what is going on in their lives. This module provides tips on how to encourage open communication and make the time to connect with your child.'  },
  {
    title: 'Be involved and encourage autonomy',
    description:'Being involved in your child’s life shows them that you care. On the other hand, encouraging children to become more independent helps them to build self confidence. In this module you will learn how to strike the right balance between positive involvement in your child’s life and encouraging their autonomy.'  },
  {
    title: 'Encourage supportive relationships',
    description:'Supportive relationships allow children to develop loving bonds with other people. Children who have supportive relationships are less likely to develop depression or anxiety problems. This module will help you to identify, encourage and support such relationships for your child.'  },
  {
    title: 'Establish family rules and consequences',
    description:'Establishing clear family rules can help guide children’s behaviour by setting out what behaviours are and are not acceptable in your family. This module will help you to understand how to work with your child to set boundaries and consequences that everyone can follow and what you can do when things sometimes don’t go to plan. '  },
  {
    title: 'Encourage good health habits',
    description:'Setting up healthy habits around diet, physical activity, sleep and screen time early on can help these habits to be maintained as children grow. This module will provide strategies to encourage healthy habits for your child and improve the mental and physical health of your whole family.'  },
  {
    title: 'Manage conflict in the home',
    description:'Parents play an important role in helping their children learn to manage conflict in a healthy way. This module will provide strategies for how to manage conflict at home, to create a safe and supportive environment for your child'  },
  {
    title: 'Support goal setting and problem solving',
    description:'Setting realistic goals helps children learn the importance of working hard to achieve something, even when it’s tough. This module will show you how to encourage your child to set goals they can actually reach and how to handle disappointment when things don’t go as planned. We’ll also talk about ways you can help your child become more independent in solving problems.'  },
  {
    title: 'Manage tough emotions',
    description:'Everyone feels anxious at times. Sometimes when anxiety becomes overwhelming for a child they may need some help to manage it so that it doesn’t get out of control. This module will help you to identify if your child is feeling anxious and how you can help them manage this anxiety.'  
  },
  {
    title: 'Manage everyday emotions',
    description:'Children who understand their emotions are more likely to be emotionally healthy. This module will provide strategies you can use to help your child identify and express their emotions in a healthy way and to develop helpful thinking patterns.'  
  },
  {
    title: 'Seek help',
    description:'All children experience ups and downs as part of growing up. Sometimes however, the downs can develop into depression and anxiety disorders and it is important for parents to be able to identify if this is the case. This module will help you to understand what to look out for and when it’s time to seek help.'  
  },
  
];
