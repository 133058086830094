import { SitePage } from 'app/layout';
import {
  Hero,
  RoofBlock,
  TargetBlock,
  StoreBlock,
  StockDetailBlock,
  CarouselBlock,
  MoreDetailBlock,
  Footer,
  IntroVideos
} from 'app/landing';
import { cyan } from '@mui/material/colors';

export default function Index() {
  return (
    <SitePage color={cyan[50]}>
      <Hero />
      <TargetBlock />
      <StoreBlock />
      <IntroVideos/>
      <StockDetailBlock />
      {/* <CarouselBlock /> */}
      <MoreDetailBlock />
      <Footer />
    </SitePage>
  );
}
