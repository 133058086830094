import styled from '@emotion/styled';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';
import target from './imgs/target.png';

export function TargetBlock() {
  const theme = useTheme();

  return (
    <Box
      component="article"
      sx={{
        padding: theme.spacing(4, 8, 0, 8),
        backgroundColor: '#f3fcfd',
        [theme.breakpoints.down('xs')]: {
          textAlign: 'center',
          padding: theme.spacing(4, 2, 0, 2),
        },
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} container alignContent="center">
          <Grid item xs={12}>
            <Typography variant="h5" color={cyan[700]}>
              Who is this program for?
            </Typography>
          </Grid>

          <Grid mb={4} item xs={12}>
            <Box>
              <Typography
                sx={{
                  textAlign: 'left',
                  padding: theme.spacing(0, 3),
                  margin: theme.spacing(0),
                  [theme.breakpoints.down('xs')]: {
                    textAlign: 'center',
                    listStylePosition: 'inside',
                  },
                }}
                component="span"
                variant="body1">
                <p style={{ marginTop: 0 }}>Parents/guardians of children aged 8-11 who:</p>
                <ul>
                  <li>Are autistic and can speak in sentences with multiple words (i.e. “I am jumping on the trampoline”)</li>
                  <li>Live in Australia</li>
                  <li>Are fluent in English</li>
                  <li>Have regular internet connection</li>
                  <li>Are not taking any medication for anxiety or depression</li>
                </ul>
                <p>
                The program is not recommended as a sole source of support for autistic children. We recommend continuing to engage with your child’s care team and/or seeking professional 
                support from a mental health professional alongside completing this program.
                </p>
              </Typography>
            </Box>
            <Button sx={{ color: cyan[700], justifySelf: 'end' }} href="/contact" target="__blank">
              Contact us
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} style={{ alignSelf: 'flex-end' }}>
          <Box display="flex">
            <TargetImage src={target} alt="Target" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const TargetImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
