import { Container, Grid, Typography, Box, Button, Paper, useTheme } from '@mui/material';
import { buttonColour } from 'app/topics/pip/utils';
import { navigate } from 'gatsby';
import { cyan } from '@mui/material/colors';
import { BasicVideo } from '../topics/pip';

export function Hero() {
  const theme = useTheme();

  return (
    <Container maxWidth="xl" sx={{ marginTop: theme.spacing(8) }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            gutterBottom
            variant="h3"
            sx={{
              color: theme.palette.grey[800],
              fontWeight: 700,
              fontSize: theme.typography.h4.fontSize,
              fontFamily: 'Source Sans Pro',
            }}>
            Partners in Parenting Kids-Autism (PiP Kids-Autism)
          </Typography>
          <Typography variant="body1">
            PiP Kids-Autism is a new, updated version of the award winning, evidence-based <b>parenting program</b> ‘Partners in
            Parenting Kids’. The program is designed to support parents of autistic children to reduce their child's risk of <b>anxiety and/or depression,</b>{' '}
            by equipping parents with strategies to improve their parenting confidence and support their child’s mental health.
            The program is online and involves interactive modules and online coaching sessions. 
          </Typography>
          <Box my={4}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: buttonColour, '&:hover': { backgroundColor: '#d5d5d5' } }}
                  onClick={() => navigate('/auth/signup')}>
                  Join Now
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ backgroundColor: buttonColour, '&:hover': { backgroundColor: '#d5d5d5' } }}
                  variant="contained"
                  onClick={() => {
                    navigate('/auth/signin');
                  }}>
                  Sign in
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

      </Grid>
    </Container>
  );
}
