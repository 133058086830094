import { Button, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { Dialog } from 'components/Dialog';

export const IntroVideoTranscript2 = () => {
    const [show, setShow] = useState(false);
    return (
      <>
        <Dialog open={show} onClose={() => setShow(false)}>
          <Dialog.Title>Transcript</Dialog.Title>
          <Dialog.Content>
            <Typography>
              Welcome to PiP Kids-Autism.
            </Typography>
            <br />
            <Typography>
              In this program, we will discuss a range of parenting strategies that are supported by the best available research evidence. However, each family is unique, so some strategies might work for you, some might not.
            </Typography>
            <br />
            <Typography>
              You know your family best, so feel free to adapt the strategies to work for you and your family. Wherever appropriate, your coach can also support you with this.
            </Typography>
            <br />
            <Typography>
              In this introductory video, we will cover some information about the program and describe the language we use.
            </Typography>
            <br />
            <Typography>
              Let's start with language. When we talk about autism, there are two types of language that are often used.
            </Typography>
            <br />
            <Typography>
              Identity-first style of language emphasises that an autistic identity is part of a child, as this impacts the way that they see the world. Identity-first language affirms that autism makes up an integral part of the child’s identity and who they are.
            </Typography>
            <br />
            <Typography>
              Person-first style of language emphasises that a child is not defined by their autistic identity, rather acknowledges that autism is one aspect of their life. Person-first language affirms that autism is separate from who the child is as a person.
            </Typography>
            <br />
            <Typography>
              Some parents prefer to use identity-first language because it signifies to others that being autistic is an important part of who their child is.
            </Typography>
            <br />
            <Typography>
              The choice of language can also help others to understand that they may need to communicate to the child in a different way or be aware of other factors such as the child’s sensory differences.
            </Typography>
            <br />
            <Typography>
              For some parents, the language they use may depend on who they are talking to. They may use one type of language with their child or other family members and another type with their GP, child’s teacher, or friends.
            </Typography>
            <br />
            <Typography>
              Depending on their experiences, other people or professionals may use person-first language more often, as this is what they have been taught or accepted in their community.
            </Typography>
            <br />
            <Typography>
              In PiP Kids-Autism, identity-first terms are used in response to feedback from other parents of autistic children. We support all autistic people, regardless of their specific diagnosis or how they identify.
            </Typography>
            <br />
            <Typography>
              Next, let's discuss neurodiversity.
            </Typography>
            <br />
            <Typography>
              Neurodiversity refers to the natural diversity of human minds. This concept acknowledges that in the world, there is a massive range of ‘kinds’ of brains and that we can benefit from being aware of and celebrating. Neurodiversity can make up a big part of someone’s identity, particularly when the environment may not be best designed for their brain.
            </Typography>
            <br />
            <Typography>
              Autism is one possible neurodivergent identity. Others might include:
            </Typography>
            <ul>
              <li>Dyslexia</li>
              <li>ADHD</li>
              <li>Tourette’s</li>
              <li>Intellectual Disability</li>
              <li>and more</li>
            </ul>
            <Typography>
              A neurotype is like a personal style or way that our brains work. It's what makes each child unique in how we think, behave, and feel.
            </Typography>
            <br />
            <Typography>
              Often those with similar neurotypes gravitate towards each other, as they may have similar communication styles or characteristics.
            </Typography>
            <br />
            <Typography>
              Let’s review some other common words you might come across when you are reading up about autism.
            </Typography>
            <br />
            <Typography>
              When we say someone is neurotypical, this means people who show patterns of thoughts and behaviours that society considers are typical of most people. In this program, we say ‘non-autistic’ to refer to anyone who does not identify as being autistic.
            </Typography>
            <br />
            <Typography>
              When we say someone is neurodivergent, this means people who have different brains from these societal ‘norms’. For example, someone who is autistic and who has ADHD might be called AuDHD.
            </Typography>
            <br />
            <Typography>
              Sensory inputs refer to the process of the brain constantly taking in information from the environment from our sensory organs, such as the ears, eyes, and mouth. When we talk about sensory experiences or inputs, this is what we mean. If you want to learn more about sensory strategies, this is covered in the Supporting your child’s sensory needs module.
            </Typography>
            <br />
            <Typography>
              Finally, you might hear the term self-regulation. This refers to the process of understanding and managing your behaviour and emotional reactions to things happening around you.
            </Typography>
            <br />
            <Typography>
              The aim of this program is not to:
            </Typography>
            <ul>
              <li>Treat or cure autism</li>
              <li>Change children to be more neurotypical</li>
              <li>Alter how autistic children communicate</li>
            </ul>
            <Typography>
              Instead, the aim of the program is to:
            </Typography>
            <ul>
              <li>Help you to support your child to develop a positive self-regard and manage anxiety and stress in a healthy way</li>
              <li>Provide you with strategies to reduce the risk of your child developing problems with depression and anxiety</li>
              <li>Increase awareness of sources of support for yourself</li>
            </ul>
            <Typography>
              Remember that the goal is not to be perfect, but to do your best to take small steps towards having a positive impact on your child’s mental health and wellbeing.
            </Typography>
            <br />
            <Typography>
              We hope you’ll find PiP Kids-Autism helpful in supporting you to do this.
            </Typography>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={() => setShow(false)}>Close</Button>
          </Dialog.Actions>
        </Dialog>
        <Link onClick={() => setShow(true)} sx={{ '&:hover': { cursor: 'pointer' } }}>
          Read transcript
        </Link>
      </>
    );
};
