import { styled } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { BasicVideo } from 'app/topics/pip';
import { IntroVideoTranscript } from './IntroVideoTranscript';
import { IntroVideoTranscript2 } from './IntroVideoTranscript2';

export function IntroVideos() {
  return (
    <Article>
      <Grid 
        container 
        spacing={4} 
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ color: cyan[700] }}>
              Introductory video{' '}
            </Typography>
            <Typography variant="body1">Watch this short video to learn more about the PiP Kids-Autism program.</Typography>
            <BasicVideo url="https://player.vimeo.com/video/1014600416?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
            <IntroVideoTranscript/>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ color: cyan[700] }}>
              About the language and common terms used in PiP Kids-Autism{' '}
            </Typography>
            <Typography variant="body1">Watch this short video to learn more about the language used in the PiP Kids-Autism program. </Typography>
            <BasicVideo url="https://player.vimeo.com/video/1014600430?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
            <IntroVideoTranscript2/>
          </Box>
        </Grid>
      </Grid>
    </Article>
  );
}

const Article = styled('article')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(8),
}));
